#label-visu{
    color: #6f7e8c;
}

#slc-visu::after{
    border-color: black!important;
    color: black!important;
    border-bottom: 1px solid rgb(207, 37, 37);
}

.css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root::after{
    border-bottom: 2px solid black;
}


.alterned_grid.MuiDataGrid-root .MuiDataGrid-row:nth-child(odd) {
    background-color: rgb(217, 220, 223);
  }
  
.alterned_grid.MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
    background-color: #ffffff;
  }

  .numTruck{
    width: 50px;
    height: 1.5rem;
    border: 1px solid #ced4da;
    text-align: center;
  }

  .numButton{
    border: none;
    width: 3rem;
    background-color: #c60000;
    color: white;
    font-weight: bold;
  }

  .numLabel {
    font-weight: 500;
    font-size: 13.5px;
}

.actionIcon{
    cursor: pointer;
}

.actionIcon:hover{
    color: #4c97d8;
}

.inputSizeNum{
  border: 1px solid #ced4da;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  outline: none;
  
}

.bold {
  font-weight: bold;

}

.est-tables{
  color:#2c95bb;
}

.disp-tables{
  color:#502cbb;
}

.ven-tables{
  color: #ab2123;
}

.dep-bks {
  color:  #fb7400;
  font-weight: bold;
}

.dep-bks {
  color:  #fb7400;
  font-weight: bold;
}

.reserv {
  color:  #3e0878;
  font-weight: bold;
}

.center-row {
  justify-content: center;
}

.align-start {
 justify-content: start!important;
}