.settings-back-button{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    margin: 1rem;
}

.gridBox{
    display: grid;
    gap: 2rem;
    overflow: auto
}

.gridBox .flexbox{
    display:flex;
    flex-direction: column;
    align-items: center;
    white-space: break-spaces;
}

.gridBox svg{
    font-size:3rem
}

.externalize{
    position:absolute;
    right:1rem;
    top:1rem;
}

.item-content-modal > .item-profile-row{
    display:flex;
    gap:1rem;
    flex-wrap: wrap;
}

.item-content-modal > .item-profile-row-informative{
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.item-content-modal > .item-profile-row > div{
    flex: 1;
}

.item-profile-row-groups{
    display:flex;
    flex-direction: column;
    gap:1rem
}

.information-table{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    height: 100%;
}

.information-table .user-table{
    flex: 1;
    overflow:hidden
}

.noborder,
.item-content-modal.noborder{
    border: none
}


.item-content-modal.nopadding{
    padding: 0
}


.user-activity-buttons{
    display:flex;
    width:100%;
    border:1px solid rgb(197, 197, 197);
    border-radius: 0.25rem
}

.user-activity-buttons button{
    flex:1
}

.payment-switch-buttons{
    display:flex;
    width: fit-content;
    border:1px solid rgb(197, 197, 197);
    border-radius: 0.25rem
}

.payment-switch-buttons > button{
    width: 6rem;
}

.profile-overflow{
    overflow: auto;
    display:flex;
    align-items: start;
    justify-content: flex-start;
}

.adress-box.onProfile{
    background-color: white;
}
.profile-save-button{
    box-shadow:1px 1px 9px 1px #707070;
    height:3rem
}

.profile-save-button .flexbox{
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:.7rem
}

.item-content-modal{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #bcbcbc;
    background: white;
    border-radius: .25rem;
}

.item-content-modal.limitedscroll{
    max-height: 10rem;
    overflow: auto
}

.item-padding{
    padding: 1rem 2rem
}

.informative-text {
    font-size: 12px;
    color: gray;
}