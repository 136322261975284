
.step3_datagrid .MuiDataGrid-cell {
    padding: 0!important;
}


.even {
    background-color: white;
  }
  
  .odd {
    background-color: rgb(217, 220, 223);
  }

  .pencil-icon{
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .pencil-icon svg{
    font-size: 18px;
  }

  .input-inside{
    width:100%
  }

  .medida-row{
    justify-content: start;
    font-weight: bold;
  }

  .descr-row{
    justify-content: start;
  }
